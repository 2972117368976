import { graphql } from 'gatsby';

export { device } from './responsive';
export * from './components';

export const defaultImage = graphql`
  fragment defaultImage on File {
    childImageSharp {
      fluid(maxWidth: $maxWidth, toFormat: JPG) {
        ...GatsbyImageSharpFluid_withWebp
        presentationWidth
      }
    }
  }
`;

export const rem = px => `${Math.round((px / 16) * 1000) / 1000}rem`;

export const getUrlVars = (href) => {
  const vars = {};
  (href || '').replace(/[?&]+([^=&]+)=([^&]*)/gi, (m,key,value) => {
      vars[key] = value;
  });
  return vars;
};
