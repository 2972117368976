import {
  createGlobalStyle
} from 'styled-components';
import 'normalize.css';

import {
  device
} from './';

export default createGlobalStyle `
  body {
    font-family: 'PT', Helvetica, Arial, sans-serif;
    margin: 0;
  }

  html, body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    font-size: 12px;

    @media ${device.tablet} {
      font-size: 14px;
    }

    @media ${device.laptop} {
      font-size: 15px;
    }

    @media ${device.laptopL} {
      font-size: 16px;
    }

    background-color: #101318;
    &, a {
      color: #FFF;
    }
  }

  .grecaptcha-badge {
    display:none !important;
  }

  * {
    -webkit-tap-highlight-color: rgba(0,0,0,0)!important;
  }

  .container {
    max-width: 1440px;
    margin: 0 30px;

    @media screen and (min-width: 1500px) {
      margin: 0 auto;
    }
  }

  .mobile-only {
    @media ${device.tablet} {
      display: none!important;
    }
  }

  .hide-mobile {
    @media ${device.tabletDown} {
      display: none!important;
    }
  }

  button {
    background: #FFF;
  }

  .button {
    display: block;
    font-weight: bold;
    padding: 12px 30px;
    text-decoration: none;
    text-align: center;
    font-size: 18px;
    background-color: #86A5FF;
    border: 1px solid #86A5FF;
    border-radius: 25px;
    margin: 30px auto;
    color: #FFF;
    outline: none;

    &.outline {
      background-color: transparent;
      color: #86A5FF;
    }

    &.loader {
      padding: 7px 40px;
    }
  }

  .back-link {
    font-size: 13px;
    text-decoration: none;
    color: #7697F8;
    letter-spacing: -0.01em;

    .icon {
      margin-right: 7px;
    }
  }

  .image-fit-contain {
    img {
      object-fit: contain !important;
    }
  }
`;