import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './Header';
import Footer from './Footer';

import GlobalStyles from '../utils/globalStyles';
import Transition from "../components/transition"

import { Main } from './index.style';
import ErrorHandler from './Errors';

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <GlobalStyles />
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 460,
          position: 'relative',
        }}
      >
        <ErrorHandler>
          <Main>
            <Transition location={location}>
              {children}
            </Transition>
          </Main>
        </ErrorHandler>
        <Footer />
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
