//@flow

import React from 'react';
import { Link } from 'gatsby-plugin-intl';
import { useStaticQuery, graphql } from 'gatsby';

import { NonStretchedImage } from '../utils';

type Props = {
  siteTitle: String,
};

const Header = ({ siteTitle }: Props) => {
  const { logo } = useStaticQuery(
    graphql`
      query Carousel {
        logo: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 220, toFormat: PNG) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
              presentationWidth
            }
          }
        }
      }
    `
  );

  return (
    <header
      style={{
        marginBottom: `1.45rem`,
        position: 'sticky',
        top: 0,
        zIndex: 1000,
      }}
    >
      <div
        style={{
          margin: `0 auto`,
          padding: `1.45rem 1.0875rem`,
          background: '#000',
        }}
      >
        <h1 style={{ margin: 0 }}>
          <Link
            to="/"
            style={{
              color: `white`,
              textDecoration: `none`,
            }}
          >
            <NonStretchedImage
              fluid={logo.childImageSharp.fluid}
              alt={siteTitle}
              loading="eager"
              className="image-fit-contain"
              fadeIn={false}
            />
          </Link>
        </h1>
      </div>
    </header>
  );
};

export default Header;
