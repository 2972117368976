//@flow

import React from 'react';
import { Link, FormattedMessage } from 'gatsby-plugin-intl';

import {
  Wrapper
} from './Footer.style';

const Footer = () => {
  return (
    <Wrapper>
      <ul className="links">
        {/* <li>
          <Link
            to="/"
          >
            <FormattedMessage id="footer.links.faq" />
          </Link>
        </li> */}
        <li>
          <a
            href="mailto:contact@ecologieducorps.com"
          >
            <FormattedMessage id="footer.links.contact" />
          </a>
        </li>
        <li>
          <Link
            to="/legal"
          >
            <FormattedMessage id="footer.links.legal" />
          </Link>
        </li>
        <li>
          <Link
            to="/privacy"
          >
            <FormattedMessage id="footer.links.privacy" />
          </Link>
        </li>

      </ul>

      <div className="copy">
        © {new Date().getFullYear()} All Rights Reserved MY BODY ECOLOGY.
        <br />
        Designed and developed by <a href="https://www.breakingweb.com" target="_blank" rel="noopener noreferrer">breaking_web</a>.
      </div>

    </Wrapper>
  );
};

export default Footer;
