import React from 'react';
const isSSR = typeof window === 'undefined';

export default class ErrorBoundary extends React.Component {
  state = {
    error: null,
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });

    if (isSSR) {
      return null;
    }

    window.Sentry.configureScope((scope) => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
    });
    window.Sentry.captureException(error);
  }

  render() {
    if (this.state.error) {
      // render fallback UI
      return <h1>Something went wrong... Please refresh the app and retry.</h1>;
    } else {
      // when there's not an error, render children untouched
      return this.props.children;
    }
  }
}