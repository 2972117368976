// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-chapter-1-js": () => import("./../src/pages/app/chapter/1.js" /* webpackChunkName: "component---src-pages-app-chapter-1-js" */),
  "component---src-pages-app-chapter-2-js": () => import("./../src/pages/app/chapter/2.js" /* webpackChunkName: "component---src-pages-app-chapter-2-js" */),
  "component---src-pages-app-chapter-3-js": () => import("./../src/pages/app/chapter/3.js" /* webpackChunkName: "component---src-pages-app-chapter-3-js" */),
  "component---src-pages-app-chapter-4-js": () => import("./../src/pages/app/chapter/4.js" /* webpackChunkName: "component---src-pages-app-chapter-4-js" */),
  "component---src-pages-app-chapter-5-js": () => import("./../src/pages/app/chapter/5.js" /* webpackChunkName: "component---src-pages-app-chapter-5-js" */),
  "component---src-pages-app-chapter-6-js": () => import("./../src/pages/app/chapter/6.js" /* webpackChunkName: "component---src-pages-app-chapter-6-js" */),
  "component---src-pages-app-index-js": () => import("./../src/pages/app/index.js" /* webpackChunkName: "component---src-pages-app-index-js" */),
  "component---src-pages-app-intro-js": () => import("./../src/pages/app/intro.js" /* webpackChunkName: "component---src-pages-app-intro-js" */),
  "component---src-pages-app-program-js": () => import("./../src/pages/app/program.js" /* webpackChunkName: "component---src-pages-app-program-js" */),
  "component---src-pages-app-quizz-js": () => import("./../src/pages/app/quizz.js" /* webpackChunkName: "component---src-pages-app-quizz-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("./../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-login-js": () => import("./../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-register-js": () => import("./../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */)
}

