import styled from 'styled-components';

export const Main = styled.main`
  margin: 20px 35px;
`;

export const Footer = styled.footer`
  text-align: center;
  margin: 30px 20px;
`;
