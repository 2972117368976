import styled from 'styled-components';

export const Wrapper = styled.footer`
  text-align: center;
  margin: 50px 20px 30px;
  border-top: 1px solid #444444;
  padding-top: 20px;

  .links {
    margin: 0 0 30px 0;
    padding: 0;

    li {
      list-style: none;

      a {
        display: block;
        text-decoration: none;
        font-family: Montserrat;
        font-size: 10px;
        text-transform: uppercase;
        padding: 10px 0;
        font-weight: bold;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`;
